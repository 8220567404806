export const USER_URL = '/me';
export const APP_URL = '/app/';
export const MYQUEST_URL = '/patient/';
export const KIOSK_URL = '/kiosk';
export const SIGNUP_URL = '/signup';
export const LOGIN_URL = '/login';
export const RESET_URL = '/password/reset';

export const CHANGE_USER_PASSWORD_URL = '/password/change';
export const DOCTOR_PATIENTS_URL = '/doctor/patients';

export const TOURS_URL = '/api/tours';

export const PROVIDERS_URL = '/identity-providers';
