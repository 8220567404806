import {
  ACCEPTED_CLINICAL_TRIAL,
  ACCEPT_AGREEMENT,
  ASSESSMENTS_LIST_CLEAR_FILTER,
  ASSESSMENTS_LIST_FILTER_ON,
  ASSESSMENTS_LIST_SEARCHED_ON,
  CANCEL_EDIT_EVENT_DATE,
  CLICK_ACCEPT_CONTEXT_SCREEN,
  CLICK_ADD_NEW_DISEASE_EXPAND,
  CLICK_ADD_NEW_DISEASE_KEBAB,
  CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE,
  CLICK_ADD_NEW_DISEASE_NEW_CONDITION,
  CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE,
  CLICK_ADD_PATIENT,
  CLICK_AGREEMENT_SCROLL_TO_BOTTOM,
  CLICK_ANSWER_NEXT_PROM,
  CLICK_APP_DOWNLOAD_BANNER,
  CLICK_AVAILABLE_QUESTIONNAIRES,
  CLICK_CANCEL_ADD_PATIENT,
  CLICK_CHANGE_LANGUAGE,
  CLICK_CONFIRM_OPT_OUT,
  CLICK_CONTINUE_QUESTIONNAIRES,
  CLICK_COPY_TO_CLIPBOARD,
  CLICK_DISEASE_TAB_EXPAND,
  CLICK_EXPAND_EVENT_TIMELINE,
  CLICK_FEATURED_PATIENT_TOGGLE,
  CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS,
  CLICK_LOGIN_FORM_ID_SUBMIT,
  CLICK_LOGIN_FORM_PASSWORD_SUBMIT,
  CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD,
  CLICK_MAIN_NAV,
  CLICK_NEW_CONDITION_OVERVIEW,
  CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND,
  CLICK_OPEN_ASSESSMENTS_NAV,
  CLICK_OPEN_FILTER_ASSESSMENTS_LIST,
  CLICK_OPEN_PATIENTS_SEARCH_FILTERS,
  CLICK_OPEN_PATIENT_CONDITION_KEBAB,
  CLICK_OPEN_PATIENT_CONDITION_ROW,
  CLICK_OPEN_PATIENT_DETAIL_EXPAND,
  CLICK_OPEN_PATIENT_EXPAND,
  CLICK_OPEN_PATIENT_KEBAB,
  CLICK_OPT_OUT,
  CLICK_OPT_OUT_BTN_IDENTITY_CHECKER,
  CLICK_PATIENT_DETAIL_FEATURED_PATIENT,
  CLICK_PATIENT_MEASUREMENTS_TAB,
  CLICK_PATIENT_MEASUREMENTS_WIDGET,
  CLICK_PROCCED_BTN_IDENTITY_CHECKER,
  CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION,
  CLICK_PROM_FINISH,
  CLICK_QUESTIONNAIRES_RESULTS,
  CLICK_QUICK_FILTER_ASSESSMENTS_LIST,
  CLICK_REFER_PATIENT_KEBAB,
  CLICK_REFER_PATIENT_SELECT_DOCTOR,
  CLICK_REFER_PATIENT_SUBMIT,
  CLICK_REQUEST_PATIENT_ASSESSMENT_KEBAB,
  CLICK_RESTART_QUESTIONNAIRE_BUTTON,
  CLICK_RESUME_QUESTIONNAIRE_BUTTON,
  CLICK_START_CLINICIAN_QUESTIONNAIRE,
  CLICK_START_PATIENT_ASSESSMENT_KEBAB,
  CLICK_START_PATIENT_QUESTIONNAIRE,
  CLICK_SEND_QUESTIONNAIRE_TO_PATIENT,
  CLICK_VALIDATE_PATIENT_CONTACT,
  CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES,
  CRO_SAVED,
  CRO_TAB_CHANGED,
  DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE,
  EDIT_EVENT_DATE,
  ENTER_CONTEXT_SCREEN,
  ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE,
  ENTER_IDENTITY_CHECKER_PAGE,
  ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE,
  ENTER_MY_QUEST,
  ENTER_PROM_RESUME,
  EVENT_CREATED,
  FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES,
  FETCH_DISEASES,
  FETCH_PROFESSIONAL_DATA,
  FETCH_USER_DATA,
  IDENTIFY_USER_AS_PATIENT,
  MYQUEST_LOGOUT,
  OPEN_EDIT_EVENT_DATE,
  OPEN_PATIENT_CONDITION_MENU_OVERVIEW,
  OPEN_PROFILE_MYQUEST,
  OPEN_SETTINGS_MYQUEST,
  PATIENT_CREATED,
  PROFESSIONAL_CHANGES_TAB_TODOLIST,
  PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST,
  PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST,
  PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST,
  PROFESSIONAL_CLICKS_SENDPROM_TODOLIST,
  PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST,
  PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST,
  PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST,
  PROFESSIONAL_OPENS_TODOLIST,
  PROFESSIONAL_SEARCHES_TODOLIST,
  PROFESSIONAL_UPDATE_FILTER_TODOLIST,
  PROFESSIONAL_UPDATE_SORT_TODOLIST,
  SELECT_PATIENT_CONDITION,
  SET_PASSWORD,
  START_NEW_CROM_NEW_CONDITION,
  START_NEW_PROM_NEW_CONDITION,
  START_PROM_SPECIFIC_TIMING_ASSESSMENTS,
  START_PROM_SPECIFIC_TIMING_OVERVIEW,
  START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES,
  SUBMITTED_PROM,
  TRACK_OPEN_TC_PAGE,
  TRACK_OPEN_ASSESSMENTS_INFO,
  TRACK_OPEN_INSTITUTION_CONSENT_PAGE,
  TRACK_OPEN_SCORE_APPRECIATION,
  TRACK_PATIENTS_LIST_CHANGE_PAGE,
  TRACK_PROFESSIONAL_GLOBAL_SEARCH,
  TRACK_PROFESSIONAL_PATIENT_SEARCH,
  TRACK_CLICK_REJECT_TC,
  TRACK_CLICK_GOBACK_REJECT_TC,
  TRACK_OPEN_CONNECT_DEVICE_PAGE,
  TRACK_CLICK_CONTINUE_CONNECT_DEVICE_PAGE,
  TRACK_CLICK_BACK_CONFIRM_DEVICE_PAGE,
  TRACK_CLICK_CONNECT_CONFIRM_DEVICE_PAGE,
  TRACK_SUCCESS_CONNECT_DEVICE,
  TRACK_CLICK_START_DEVICE_WELCOME_PAGE,
  TRACK_OPEN_DEVICE_WELCOME_PAGE,
  TRACK_OPEN_DEVICE_NO_QUESTIONNAIRES,
  TRACK_OPEN_DEVICE_LANGUAGE_MENU,
  TRACK_CLICK_DEVICE_LANGUAGE_MENU
} from '../../src/constants/actionTypes';
import {
  ACCEPT_AGREEMENT_EVENT,
  ACCEPT_CLINICAL_TRIAL_EVENT,
  CLICK_APP_DOWNLOAD_BANNER_EVENT,
  CLICK_LOGIN_FORM_ID_SUBMIT_EVENT,
  CLICK_LOGIN_FORM_PASSWORD_SUBMIT_EVENT,
  CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD_EVENT,
  LOCATION_CHANGED_EVENT,
  PATIENT_CREATED_EVENT,
  SET_PASSWORD_EVENT,
  SUBMITTED_PROM_EVENT
} from './events';

export const mixpanelMiddleware = store => next => action => {
  if (!window.MIXPANEL_ENABLED) {
    next(action);
    return;
  }

  switch (action.type) {
    case IDENTIFY_USER_AS_PATIENT: {
      const data = action.payload;
      mixpanel.identify(data.uuid);
      mixpanel.people.set({
        isPatient: true
      });
      break;
    }

    case FETCH_USER_DATA: {
      const userData = action.payload;
      mixpanel.identify(userData.uuid);
      if (userData.is_staff) {
        mixpanel.opt_out_tracking();
      }

      break;
    }

    case FETCH_PROFESSIONAL_DATA: {
      const data = action.payload;
      const isProfessional = Boolean(data && data.id);
      const professionalInstitutions = data.institutions || [];

      mixpanel.people.set({
        isProfessional
      });

      mixpanel.people.union({
        institutions: professionalInstitutions.map(i => i.institution.id),
        'institution-diseases': professionalInstitutions.reduce(
          (p, i) => [...p, ...(i.diseases || []).map(d => `${i.institution.id}-${d.slug}`)],
          []
        )
      });
      break;
    }

    case FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES: {
      const data = action.payload || [];
      const isPatient = data.some(hs => hs.patient && hs.patient.id);
      const institutions = _.flatten(data.map(hs => (hs || {}).institutions));

      mixpanel.people.set({
        isPatient
      });

      mixpanel.people.union({
        institutions: institutions.map(i => (i || {}).id)
      });

      break;
    }

    case '@@router/LOCATION_CHANGE': {
      mixpanel.track(LOCATION_CHANGED_EVENT, {
        page: action.payload.location.pathname
      });
      break;
    }

    case PATIENT_CREATED: {
      const data = action.payload.data || {};
      const state = store.getState();
      mixpanel.track(PATIENT_CREATED_EVENT, {
        isAnonymous: data.isAnonymous || false,
        disease: data.disease.slug,
        institution: (data.institution || (state.professional && state.professional.selectedInstitution) || {}).id
      });
      break;
    }

    case FETCH_DISEASES: {
      const data = action.payload || [];
      const diseases = data && data.map(pd => pd.disease.slug);

      mixpanel.people.union({
        diseases
      });
      break;
    }

    case SET_PASSWORD: {
      mixpanel.track(SET_PASSWORD_EVENT);
      break;
    }

    case ACCEPT_AGREEMENT: {
      mixpanel.track(ACCEPT_AGREEMENT_EVENT);
      break;
    }

    case ACCEPTED_CLINICAL_TRIAL: {
      mixpanel.track(ACCEPT_CLINICAL_TRIAL_EVENT);
      break;
    }

    case CLICK_APP_DOWNLOAD_BANNER: {
      mixpanel.track(CLICK_APP_DOWNLOAD_BANNER_EVENT);
      break;
    }

    case SUBMITTED_PROM: {
      mixpanel.track(SUBMITTED_PROM_EVENT);
      break;
    }

    case CLICK_LOGIN_FORM_ID_SUBMIT: {
      mixpanel.track(CLICK_LOGIN_FORM_ID_SUBMIT_EVENT);
      break;
    }

    case CLICK_LOGIN_FORM_PASSWORD_SUBMIT: {
      mixpanel.track(CLICK_LOGIN_FORM_PASSWORD_SUBMIT_EVENT);
      break;
    }

    case CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD: {
      mixpanel.track(CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD_EVENT);
      break;
    }

    case CLICK_ADD_PATIENT: {
      mixpanel.track(CLICK_ADD_PATIENT);
      break;
    }
    case CLICK_VALIDATE_PATIENT_CONTACT: {
      mixpanel.track(CLICK_VALIDATE_PATIENT_CONTACT);
      break;
    }
    case CLICK_START_PATIENT_QUESTIONNAIRE: {
      mixpanel.track(CLICK_START_PATIENT_QUESTIONNAIRE);
      break;
    }
    case CLICK_START_CLINICIAN_QUESTIONNAIRE: {
      mixpanel.track(CLICK_START_CLINICIAN_QUESTIONNAIRE);
      break;
    }
    case CLICK_CANCEL_ADD_PATIENT: {
      mixpanel.track(CLICK_CANCEL_ADD_PATIENT);
      break;
    }

    case CLICK_OPEN_PATIENT_EXPAND: {
      mixpanel.track(CLICK_OPEN_PATIENT_EXPAND);
      break;
    }

    case CLICK_OPEN_PATIENT_DETAIL_EXPAND: {
      mixpanel.track(CLICK_OPEN_PATIENT_DETAIL_EXPAND);
      break;
    }

    case CLICK_OPEN_PATIENT_KEBAB: {
      mixpanel.track(CLICK_OPEN_PATIENT_KEBAB);
      break;
    }

    case CLICK_OPEN_PATIENT_CONDITION_KEBAB: {
      mixpanel.track(CLICK_OPEN_PATIENT_CONDITION_KEBAB);
      break;
    }

    case CLICK_START_PATIENT_ASSESSMENT_KEBAB: {
      mixpanel.track(CLICK_START_PATIENT_ASSESSMENT_KEBAB);
      break;
    }

    case CLICK_REQUEST_PATIENT_ASSESSMENT_KEBAB: {
      mixpanel.track(CLICK_REQUEST_PATIENT_ASSESSMENT_KEBAB);
      break;
    }
    case CLICK_REFER_PATIENT_KEBAB: {
      mixpanel.track(CLICK_REFER_PATIENT_KEBAB);
      break;
    }

    case CLICK_REFER_PATIENT_SUBMIT: {
      mixpanel.track(CLICK_REFER_PATIENT_SUBMIT);
      break;
    }

    case CLICK_REFER_PATIENT_SELECT_DOCTOR: {
      mixpanel.track(CLICK_REFER_PATIENT_SELECT_DOCTOR);
      break;
    }

    case CLICK_OPEN_PATIENT_CONDITION_ROW: {
      mixpanel.track(CLICK_OPEN_PATIENT_CONDITION_ROW);
      break;
    }

    case CLICK_OPEN_PATIENTS_SEARCH_FILTERS: {
      mixpanel.track(CLICK_OPEN_PATIENTS_SEARCH_FILTERS);
      break;
    }

    case TRACK_PROFESSIONAL_GLOBAL_SEARCH: {
      mixpanel.track(TRACK_PROFESSIONAL_GLOBAL_SEARCH);
      break;
    }

    case TRACK_PROFESSIONAL_PATIENT_SEARCH: {
      mixpanel.track(TRACK_PROFESSIONAL_PATIENT_SEARCH);
      break;
    }

    case TRACK_PATIENTS_LIST_CHANGE_PAGE: {
      mixpanel.track(TRACK_PATIENTS_LIST_CHANGE_PAGE);
      break;
    }

    case ENTER_PROM_RESUME: {
      mixpanel.track(ENTER_PROM_RESUME);
      break;
    }

    case CLICK_PROM_FINISH: {
      mixpanel.track(CLICK_PROM_FINISH);
      break;
    }

    case CLICK_OPEN_ASSESSMENTS_NAV: {
      const { navUid } = action.payload;
      switch (navUid) {
        case 'assessments':
          mixpanel.track(CLICK_OPEN_ASSESSMENTS_NAV);
          break;
        // when adding another track for nav click insert here the action
        default:
          break;
      }
      break;
    }

    case CLICK_EXPAND_EVENT_TIMELINE: {
      const { diseaseId, patientId } = action.payload;
      mixpanel.track(CLICK_EXPAND_EVENT_TIMELINE, { diseaseId, patientId });
      break;
    }

    case START_PROM_SPECIFIC_TIMING_OVERVIEW: {
      const { diseaseId, patientId, timingId, diseaseEventId } = action.payload;
      mixpanel.track(START_PROM_SPECIFIC_TIMING_OVERVIEW, {
        diseaseId,
        patientId,
        timingId,
        diseaseEventId
      });
      break;
    }

    case START_PROM_SPECIFIC_TIMING_ASSESSMENTS: {
      const { diseaseId, patientId, timingId, diseaseEventId } = action.payload;
      mixpanel.track(START_PROM_SPECIFIC_TIMING_ASSESSMENTS, {
        diseaseId,
        patientId,
        timingId,
        diseaseEventId
      });
      break;
    }

    case OPEN_EDIT_EVENT_DATE: {
      mixpanel.track(OPEN_EDIT_EVENT_DATE);
      break;
    }

    case EDIT_EVENT_DATE: {
      const { patientId, diseaseId, eventId } = action.payload;
      mixpanel.track(EDIT_EVENT_DATE, { patientId, diseaseId, eventId });
      break;
    }

    case CANCEL_EDIT_EVENT_DATE: {
      mixpanel.track(CANCEL_EDIT_EVENT_DATE);
      break;
    }

    case EVENT_CREATED: {
      const { event, disease } = action.payload;
      mixpanel.track(EVENT_CREATED, { event, disease });
      break;
    }

    case CRO_TAB_CHANGED: {
      const { label } = action.payload;
      mixpanel.track(CRO_TAB_CHANGED, { selected_tab: label });
      break;
    }

    case CRO_SAVED: {
      const { patientId, disease, assessmentKey } = action.payload;
      mixpanel.track(CRO_SAVED, { patientId, disease, assessmentKey });
      break;
    }

    case CLICK_COPY_TO_CLIPBOARD: {
      mixpanel.track(CLICK_COPY_TO_CLIPBOARD);
      break;
    }

    case TRACK_OPEN_SCORE_APPRECIATION: {
      mixpanel.track(TRACK_OPEN_SCORE_APPRECIATION);
      break;
    }

    case TRACK_OPEN_ASSESSMENTS_INFO: {
      mixpanel.track(TRACK_OPEN_ASSESSMENTS_INFO);
      break;
    }

    case CLICK_ANSWER_NEXT_PROM: {
      mixpanel.track(CLICK_ANSWER_NEXT_PROM);
      break;
    }

    case CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND: {
      const { option } = action.payload;
      mixpanel.track(CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND, { option });
      break;
    }

    case CLICK_AGREEMENT_SCROLL_TO_BOTTOM: {
      const { option } = action.payload;
      mixpanel.track(CLICK_AGREEMENT_SCROLL_TO_BOTTOM, { option });
      break;
    }

    case CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE: {
      mixpanel.track(CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE);
      break;
    }

    case CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE: {
      const { data } = action.payload;
      mixpanel.track(CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE, {
        data
      });
      break;
    }
    case CLICK_ADD_NEW_DISEASE_NEW_CONDITION: {
      const { data } = action.payload;
      mixpanel.track(CLICK_ADD_NEW_DISEASE_NEW_CONDITION, { data });
      break;
    }

    case OPEN_PATIENT_CONDITION_MENU_OVERVIEW: {
      mixpanel.track(OPEN_PATIENT_CONDITION_MENU_OVERVIEW);
      break;
    }

    case SELECT_PATIENT_CONDITION: {
      const { disease } = action.payload;
      mixpanel.track(SELECT_PATIENT_CONDITION, { disease });
      break;
    }

    case CLICK_NEW_CONDITION_OVERVIEW: {
      mixpanel.track(CLICK_NEW_CONDITION_OVERVIEW);
      break;
    }

    case START_NEW_PROM_NEW_CONDITION: {
      mixpanel.track(START_NEW_PROM_NEW_CONDITION);
      break;
    }

    case START_NEW_CROM_NEW_CONDITION: {
      mixpanel.track(START_NEW_CROM_NEW_CONDITION);
      break;
    }

    case CLICK_ADD_NEW_DISEASE_KEBAB: {
      mixpanel.track(CLICK_ADD_NEW_DISEASE_KEBAB);
      break;
    }

    case CLICK_ADD_NEW_DISEASE_EXPAND: {
      mixpanel.track(CLICK_ADD_NEW_DISEASE_EXPAND);
      break;
    }

    case CLICK_DISEASE_TAB_EXPAND: {
      const { disease } = action.payload;
      mixpanel.track(CLICK_DISEASE_TAB_EXPAND, { disease });
      break;
    }

    case CLICK_PATIENT_MEASUREMENTS_TAB: {
      const { name } = action.payload;
      mixpanel.track(CLICK_PATIENT_MEASUREMENTS_TAB, { name });
      break;
    }

    case CLICK_PATIENT_MEASUREMENTS_WIDGET: {
      const { name } = action.payload;
      mixpanel.track(CLICK_PATIENT_MEASUREMENTS_WIDGET, { name });
      break;
    }
    case CLICK_FEATURED_PATIENT_TOGGLE: {
      const { type } = action.payload;
      mixpanel.track(CLICK_FEATURED_PATIENT_TOGGLE, { type });
      break;
    }
    case CLICK_PATIENT_DETAIL_FEATURED_PATIENT: {
      mixpanel.track(CLICK_PATIENT_DETAIL_FEATURED_PATIENT);
      break;
    }

    case CLICK_ACCEPT_CONTEXT_SCREEN: {
      const userData = action.payload;
      mixpanel.track(CLICK_ACCEPT_CONTEXT_SCREEN, { userData });
      break;
    }
    case ENTER_CONTEXT_SCREEN: {
      const userData = action.payload;
      mixpanel.track(ENTER_CONTEXT_SCREEN, { userData });
      break;
    }

    case CLICK_MAIN_NAV: {
      const { institution, user, menu } = action.payload;
      mixpanel.track(CLICK_MAIN_NAV, { institution, user, menu });
      break;
    }
    case CLICK_QUICK_FILTER_ASSESSMENTS_LIST: {
      const { institution, user, type } = action.payload;
      mixpanel.track(CLICK_QUICK_FILTER_ASSESSMENTS_LIST, {
        institution,
        user,
        type
      });
      break;
    }
    case CLICK_OPEN_FILTER_ASSESSMENTS_LIST: {
      const { institution, user } = action.payload;
      mixpanel.track(CLICK_QUICK_FILTER_ASSESSMENTS_LIST, {
        institution,
        user
      });
      break;
    }
    case ASSESSMENTS_LIST_FILTER_ON: {
      const { institution, user, type } = action.payload;
      mixpanel.track(ASSESSMENTS_LIST_FILTER_ON, { institution, user, type });
      break;
    }
    case ASSESSMENTS_LIST_CLEAR_FILTER: {
      const { institution, user } = action.payload;
      mixpanel.track(ASSESSMENTS_LIST_CLEAR_FILTER, { institution, user });
      break;
    }
    case ASSESSMENTS_LIST_SEARCHED_ON: {
      const { institution, user } = action.payload;
      mixpanel.track(ASSESSMENTS_LIST_SEARCHED_ON, { institution, user });
      break;
    }
    case CLICK_OPT_OUT: {
      const info = action.payload;
      mixpanel.track(CLICK_OPT_OUT, { info });
      break;
    }
    case CLICK_CONFIRM_OPT_OUT: {
      const info = action.payload;
      mixpanel.track(CLICK_CONFIRM_OPT_OUT, { info });
      break;
    }
    case ENTER_IDENTITY_CHECKER_PAGE: {
      const userData = action.payload;
      mixpanel.track(ENTER_IDENTITY_CHECKER_PAGE, { userData });
      break;
    }
    case CLICK_PROCCED_BTN_IDENTITY_CHECKER: {
      const userData = action.payload;
      mixpanel.track(CLICK_PROCCED_BTN_IDENTITY_CHECKER, { userData });
      break;
    }
    case ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE: {
      const userData = action.payload;
      mixpanel.track(ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE, { userData });
      break;
    }
    case CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION: {
      const userData = action.payload;
      mixpanel.track(CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION, {
        userData
      });
      break;
    }
    case CLICK_OPT_OUT_BTN_IDENTITY_CHECKER: {
      const userData = action.payload;
      mixpanel.track(CLICK_OPT_OUT_BTN_IDENTITY_CHECKER, { userData });
      break;
    }
    case ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE: {
      const userData = action.payload;
      mixpanel.track(ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE, { userData });
      break;
    }

    case CLICK_RESTART_QUESTIONNAIRE_BUTTON: {
      const { institution, timing, patientId, patientDiseaseId } = action.payload;
      mixpanel.track(CLICK_RESTART_QUESTIONNAIRE_BUTTON, {
        institution,
        timing,
        patientId,
        patientDiseaseId
      });
      break;
    }
    case CLICK_RESUME_QUESTIONNAIRE_BUTTON: {
      const { institution, timing, patientId, patientDiseaseId } = action.payload;
      mixpanel.track(CLICK_RESUME_QUESTIONNAIRE_BUTTON, {
        institution,
        timing,
        patientId,
        patientDiseaseId
      });
      break;
    }
    case CLICK_CHANGE_LANGUAGE: {
      const info = action.payload;
      mixpanel.track(CLICK_CHANGE_LANGUAGE, { info });
      break;
    }
    case ENTER_MY_QUEST: {
      mixpanel.track(ENTER_MY_QUEST);
      break;
    }
    case CLICK_AVAILABLE_QUESTIONNAIRES: {
      mixpanel.track(CLICK_AVAILABLE_QUESTIONNAIRES);
      break;
    }
    case CLICK_CONTINUE_QUESTIONNAIRES: {
      mixpanel.track(CLICK_CONTINUE_QUESTIONNAIRES);
      break;
    }
    case CLICK_QUESTIONNAIRES_RESULTS: {
      mixpanel.track(CLICK_QUESTIONNAIRES_RESULTS);
      break;
    }
    case START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES: {
      const { info } = action.payload;
      mixpanel.track(START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES, { info });
      break;
    }
    case CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES: {
      const { info } = action.payload;
      mixpanel.track(CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES, {
        info
      });
      break;
    }
    case DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE: {
      const { info } = action.payload;
      mixpanel.track(DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE, { info });
      break;
    }
    case OPEN_PROFILE_MYQUEST: {
      mixpanel.track(OPEN_PROFILE_MYQUEST);
      break;
    }
    case OPEN_SETTINGS_MYQUEST: {
      mixpanel.track(OPEN_SETTINGS_MYQUEST);
      break;
    }
    case MYQUEST_LOGOUT: {
      mixpanel.track(MYQUEST_LOGOUT);
      break;
    }
    case CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS: {
      const userData = action.payload;
      mixpanel.track(CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS, { userData });
      break;
    }
    case PROFESSIONAL_OPENS_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_OPENS_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CHANGES_TAB_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CHANGES_TAB_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_SEARCHES_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_SEARCHES_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_UPDATE_SORT_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_UPDATE_SORT_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_UPDATE_FILTER_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_UPDATE_FILTER_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_SENDPROM_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_SENDPROM_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST, { info });
      break;
    }
    case PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST, {
        info
      });
      break;
    }
    case PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST: {
      const info = action.payload;
      mixpanel.track(PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST, { info });
      break;
    }
    case TRACK_OPEN_TC_PAGE: {
      const info = action.payload;
      mixpanel.track(TRACK_OPEN_TC_PAGE, { ...info });
      break;
    }
    case TRACK_CLICK_REJECT_TC: {
      const info = action.payload;
      mixpanel.track(TRACK_CLICK_REJECT_TC, { ...info });
      break;
    }
    case TRACK_CLICK_GOBACK_REJECT_TC: {
      const info = action.payload;
      mixpanel.track(TRACK_CLICK_GOBACK_REJECT_TC, { ...info });
      break;
    }
    case TRACK_OPEN_INSTITUTION_CONSENT_PAGE: {
      const info = action.payload;
      mixpanel.track(TRACK_OPEN_INSTITUTION_CONSENT_PAGE, { ...info });
      break;
    }
    case CLICK_SEND_QUESTIONNAIRE_TO_PATIENT: {
      const info = action.payload;
      mixpanel.track(CLICK_SEND_QUESTIONNAIRE_TO_PATIENT, { ...info });
      break;
    }
    case TRACK_OPEN_CONNECT_DEVICE_PAGE:
    case TRACK_CLICK_CONTINUE_CONNECT_DEVICE_PAGE:
    case TRACK_CLICK_BACK_CONFIRM_DEVICE_PAGE:
    case TRACK_CLICK_CONNECT_CONFIRM_DEVICE_PAGE:
    case TRACK_SUCCESS_CONNECT_DEVICE:
    case TRACK_OPEN_DEVICE_WELCOME_PAGE:
    case TRACK_CLICK_START_DEVICE_WELCOME_PAGE:
    case TRACK_OPEN_DEVICE_NO_QUESTIONNAIRES:
    case TRACK_OPEN_DEVICE_LANGUAGE_MENU:
    case TRACK_CLICK_DEVICE_LANGUAGE_MENU: {
      const params = { ...action.payload, product: localStorage.getItem('product') };

      mixpanel.track(action.type, params);
      break;
    }
    default:
      break;
  }
  next(action);
};

export default mixpanelMiddleware;
